@import '~@nationwide-bolt/css-core/dist/css/bolt-core.css';
@import '~@nationwide-bolt/tokens/dist/scss/tokens';
@import "~bootstrap/dist/css/bootstrap.min.css";

html, body { height: 100%; }

body {
  margin: 0;
  font-family: "nw-primary","proxima-nova","Helvetica Neue","Helvetica",sans-serif;
}

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.container {
  max-width: 100%;
  margin: auto !important;

}
.page-header {
  padding: 2rem 0 1rem 0;
  border-radius: 5px 5px 0 0;
}

.titleLogo{
  padding-right: 0;
}

.titleText {
  padding-left: 1rem;
}

.main {
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
  font-weight: normal;
  line-height: 1.5;
  color: #222;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 2;
}

.main-body {
  padding: 0px;
}

.mat-form-field {
  display: flex !important;
  flex-direction: column;
  flex-grow: 2;
  max-width: 400px;
  margin: .75rem .5rem;
}

.mat-form-field label {
  color:  #222;
  display: block;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: .5rem;
}

.mat-form-field input, select {
  background: #f6f6f6;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom: 2px solid #6c6c6c;
  box-sizing: border-box;
  color: #222;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 100%;
  padding: calc(.75rem - 1px) .75rem calc(.75rem - 2px);
  text-decoration: none;
  transition: background 350ms,border 350ms,color 350ms,box-shadow 350ms;
  width: 100%;
}

.mat-form-field input#ext{
  width: 50%;
}

.mat-radio-group .mat-checkbox-group {
  margin: 5px;
}

.mat-radio-group .mat-radio-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 20px 0;
}

.mat-radio-group .mat-radio-button input {
  height: 1.3rem;
  width: 1.3rem;
}

.mat-radio-group .mat-radio-button label {
  padding-top: 5px;
  padding-left: 10px;
}


.mat-checkbox-group .mat-checkbox {
  display: flex;
  flex: 2;
  flex-direction: row;
  margin-left: 8px;
}

.mat-checkbox-group .mat-checkbox input {
  align-items: center;
  height: 1.3rem;
  width: 1.3rem;
}

.mat-checkbox-group .mat-checkbox label {
  padding-top: 5px;
  padding-left: 10px;
}

.form-check-input[type=checkbox] {
  border-radius: 0;
}

.input-group .date {
  position: relative;
}

.input-group-append {
  position: absolute;
  bottom: 2.3125rem;
  right: 1.25rem;
}

.input-group-append .btn {
  padding: 0.25rem 0.50rem;
  margin-right: -15px;
}

button bolt-icon {
  vertical-align: bottom;
}

input:focus, input:hover {
  outline:2px solid #b9ddfe;
}

.form-group {
  border: none;
}

.calendar {
  border: none;
}

.modal-actions {
  width: 100%;
  display: inline-block;
  /*justify-content: space-between;@extend*/
  background: #eaeaea;
  border-radius: 0 0 5px 5px;
  margin-bottom: unset;
}

.modal-actions .action-schedule{
  float: right;
}

.modal-actions .action-reschedule{
  float: left;
  margin-left: 20px;
}

.modal-actions .action-cancel{
  float: left;
}

.modal-actions .action-do-not-cancel {
  float: right;
}

.modal-actions .action-cancel-call {
  float: left;
}

.modal-footer {
  background: #eaeaea;
  text-align: center;
  border-radius: 0 0 5px 5px;
  padding: 1rem 2rem;
}

.modal-footer button {
  padding: .75rem;
}

.modal-footer .bolt-row {
  text-align: center;
  margin-bottom: 0;
}

.error-msg {
  margin-top: .5rem;
  color: #EB0004;
}

.error-msg span {
  margin-top: .3rem;
}

ngb-datepicker {
  background: #fff;
  border-collapse: separate;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  z-index: 999;
}

#resheduleText {
  float: right;
}

label > span {
  font-weight: normal;
}

.bolt-background-blue-dark .bolt-button.bolt-button-wc--secondary:focus {
  background: rgba(255, 255, 255, 0.7);
  color: #222;
}

.bolt-button-wc--primary.bolt-button:focus:not([aria-disabled=true]).bolt-button {
  background: #004d94;
  color: #fff;
}

.bolt-button-wc.bolt-button {
  box-sizing: border-box;
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-family: "nw-primary","proxima-nova","Helvetica Neue","Helvetica",sans-serif;
  border-radius: 4px;
  color: #fff;
  background: #0067c5;
  border: 0;
  font-weight: 400;
  transition: all 350ms;
}

ngb-datepicker {
  position: absolute;
  background-color: white;

  .ngb-dp-header {
      background-color: #EFEFEF;
  }

  select {
      background-color: #EFEFEF;
      border-bottom: none;
      color: #222;
  }

}

[ngbDatepickerDayView] {
  background: transparent;
  border-radius: .25rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  width: 2rem;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: small;
}

bolt-field-error {
  margin-top: .5rem;
}

.when-to-call {
  margin: 1.0rem  0.5rem 0.5rem;
}

.when-to-call legend {
  font-size: 1.125rem;
  line-height: 170%;
  color: #222;
  font-weight: 700;
}

.call-me-later {
  margin-left: 2.0rem;
}

.call-me-later .mat-form-field:first-of-type {
  margin-top: 0;
}

.btn-outline-secondary:hover {
  background-color: #ccc;
}

.mat-radio-button:last-of-type {
  padding-bottom:10px;
}

.call-date-info {
  font-size: 1.0rem;
}

.error-msg bolt-icon[name="notification-error"] {
  vertical-align: sub;
}

.bg-container, .bg-container-m {
  border: 0px solid #6c6c6c;
  box-shadow: 0 8px 16px rgba(0,0,0,0.30), 0 4px 8px rgba(0,0,0,0.22);
  & .bg-header {
      background: #6c6c6c;
      color: #fff;
      width: 100%;
      margin: 0;
      padding-left: 20px;
      font-size: 16px;
      height: 45px;
      padding-top: 10px;
  }

  & .bg-content {
      padding: 20px;
  }
}
